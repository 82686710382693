<template>
  <div class="book-landing">
    <div class="book-landing__header">
      <h1 class="book-landing__header_title" align="center">Books</h1>
    </div>

    <div class="book-landing__body">
      <router-link class="book-landing__body__item" to="/library/the-grotta-home-by-richard-meier">
        <img
          class="book-landing__body__item-image"
          v-webp="require('@/assets/images/books/home-book-landing-cover.png')"
          src="@/assets/images/books/home-book-landing-cover.png"
          alt="The Grotta Home by Richard Meier"
          decoding="async"
          loading="lazy"
        />
        <h2 class="book-landing__body__item-title text-title">
          The Grotta Home by Richard Meier <br />
          A Marriage of Architecture and Craft
        </h2>
        <!-- <p class="book-landing__body__item-description text-description">Andersons</p> -->
      </router-link>
      <router-link class="book-landing__body__item" to="/library/the-grotta-house-by-richard-meier">
        <img
          class="book-landing__body__item-image"
          v-webp="require('@/assets/images/books/house-book-cover.png')"
          src="@/assets/images/books/house-book-cover.png"
          alt="The Grotta House by Richard Meier"
          decoding="async"
          loading="lazy"
        />
        <h2 class="book-landing__body__item-title text-title">The Grotta House by Richard Meier</h2>
        <!-- <p class="book-landing__body__item-description text-description">Andersons</p> -->
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  components: {},

  data() {
    return {};
  },

  head: {
    title: function () {
      return {
        inner: "GH - Books",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  computed: {},

  async created() {
    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
      {
        title: "Books",
        to: "/library/books",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapMutations(["setBreadcrumbs"]),
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.book-landing {
  min-height: 100vh;
  min-width: 100vw;
  background-color: $lightBlack;
  margin-top: 156px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_title {
      font-size: 62px;
      line-height: 1.1;
      font-family: $fontBodoni;
      @media only screen and (max-width: $xs) {
        font-size: 39px;
        line-height: 1.28;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 52rem));
    grid-column-gap: 4rem;
    grid-row-gap: 6.5rem;
    padding: 7rem 10.5rem 11rem;
    justify-content: center;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
      grid-row-gap: 7.5rem;
      padding: 10.8rem 2rem 11rem;
    }
    &__item {
      display: block;
      cursor: pointer;
      text-decoration: none;
      max-width: 52rem;
      &-image {
        width: 100%;
        max-width: 52rem;
        border-radius: 4px;
        @media screen and (max-width: $xs) {
          width: 100%;
          height: 90vw;
        }
      }
      &-title {
        font-size: 2.4rem;
        line-height: 1.2;
        margin: 8px 0 1rem;
        @media screen and (max-width: $xs) {
          margin: 14px 0 4px;
        }
      }
    }
  }
}
</style>

